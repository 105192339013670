.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    max-width: 365px;
}

.dropdown-selected {
    width: 100%;
    max-width: 365px;
    height: 50px;
    background-color: #F9F9F9;
    border: none;
    border-radius: 10px;
    color: #B3B3B3;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none; 
    background-image: url('../../../../images/arrow-drop.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 200;
}

.dropdown-item {
    padding: 10px;
    color: #B3B3B3;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    border-bottom: 1px solid #ccc;
    z-index: 200;
    position: relative;
    background: #fff;
}

.dropdown-item:last-child {
    border-bottom: none;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

@media screen and (max-width:860px) {
    .dropdown-selected {
        width: 100%;
        max-width: unset;
    }
    .custom-dropdown {
        width: 100%;
        max-width: unset;
    }
}