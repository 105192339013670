.rating {
    width: 100%;
    margin-top: 160px;
    margin-bottom: 160px;
}

.rating__container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

}

.rating__shot {
    position: absolute;
    z-index: -100;
    left: -120px;
    top:-80px;
}

.rating__title {
    padding-top: 34px;
    margin-bottom: 80px;
}

.rating__title span{
    color: #FF0000;
}

.rating__title span:first-child{
    color: #ffffff;
}

.rating__table_header {
    list-style-type: none;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.278fr 1.030fr 1.426fr 1fr 0.974fr 0.870fr 1.030fr;
    grid-template-columns: 0.278fr 1.030fr 1.426fr 1fr 0.974fr 0.870fr 1.030fr;
    border-bottom: #909090 1px solid;
    padding-bottom: 20px; 
    margin-bottom: 15px;
}

.rating__table_header-item {
    text-align: center;
    font-family: Inter;
    color: #909090;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

.rating__table_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
}

.rating__item {
    padding-left: 10px;
    width: 100%;
    height: 129px;
    background-color: #F3F3F3;
    border-radius: 16px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.278fr 1.030fr 1.426fr 1fr 0.974fr 0.870fr 1.030fr;
    grid-template-columns: 0.278fr 1.030fr 1.426fr 1fr 0.974fr 0.870fr 1.030fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    justify-items: center;
}


.rating__item:nth-child(even) {
    background-color: #E8F3F2;
}

.rating__item_nikname {
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    max-width: 280px;
}

.rating__item_progress,
.rating__item_country,
.rating__item_city {
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
}

.rating__item_level {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 6.76px;
}

.rating__item_span {
    display: none;
}

.rating__item_num {
    -ms-grid-column-align: left;
        justify-self: left;
    padding-left: 10px;
}

.btn.rating__full-rating {
    width: 100%;
    height: 50px;
    max-width: 403px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    color: #fff;
    background-color: #161619;
    font-family: Inter;
    font-size: 18px;
    line-height: 130%;
    font-weight: 500;
    margin-top: 58px;
}

@media screen and (max-width: 1280px) {
    .rating__item_nikname {
        max-width: 180px;
    }
}

@media screen and (max-width: 1024px) {
    .rating {
        margin-top: 100px;
        margin-bottom: 124px;
    }

    .rating__container {
        max-width: 600px;
        margin: 0 auto;
        position: relative;
    }

    .rating__table_header {
        display: none;
    }

    .rating__item {
        height: 195px;
        position: relative;
        -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
        -ms-grid-rows: 33px 33px 33px 33px;
        grid-template-rows: 33px 33px 33px 33px;
        -ms-flex-line-pack: end;
            align-content: end;
        justify-items: left;
        padding: 20px;
        border-radius: 12px;
    }

    .rating__item > *:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }

    .rating__item > *:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
    }

    .rating__item > *:nth-child(3) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
    }

    .rating__item > *:nth-child(4) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
    }

    .rating__item > *:nth-child(5) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
    }

    .rating__item > *:nth-child(6) {
        -ms-grid-row: 3;
        -ms-grid-column: 2;
    }

    .rating__item > *:nth-child(7) {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
    }

    .rating__item > *:nth-child(8) {
        -ms-grid-row: 4;
        -ms-grid-column: 2;
    }

    .rating__item_avatar {
        position: absolute;
        height: 79px;
        width: 79px;
        top:20px;
        right: 20px;
    }

    .rating__item_num {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 18px;
        -ms-grid-column-align: unset;
            justify-self: unset;
        padding-left: 0;
    }

    .rating__item_nikname {
        position: absolute;
        top: 24px;
        left: 49px;
        font-size: 14px;
        max-width: 250px;
    }

    .rating__item_span {
        display: inline;
        color: #909090;
        font-size: 10px;
        font-family: Inter;
        font-weight: 400;
        line-height: 130%;
    }

    .rating__item_progress,
    .rating__item_country,
    .rating__item_city {
        font-size: 14px;
    }

    .rating__item_level {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 3.79px;
    } 

    .rating__item_level-bullet {
        width: 5.18px;
        height: 14.05px;
    }

    .rating__item_span,
    .rating__item_progress,
    .rating__item_level,
    .rating__item_country,
    .rating__item_city {
        -webkit-transform: translateY(9px);
            -ms-transform: translateY(9px);
                transform: translateY(9px);
    }

    .btn.rating__full-rating {
        height: 45px;
        max-width: 403px;
        font-size: 14px;
        margin-top: 37px;
    }
}

@media screen and (max-width: 860px) {
    .rating {
        margin-bottom: 64px;
    }

    .rating__shot {
        position: absolute;
        width: 100px;
        height: auto;
        left: -38px;
        top: 2px;
    }

    .rating__title {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 130%;
    }
}

@media screen and (max-width: 430px) {
    .rating__item_nikname {
        max-width: 160px;
    }
}