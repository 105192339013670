.signup__title_container {
    position: relative;
}

.signup__shot {
    position: absolute;
    left:-170px;
    top:-130px;
    z-index: 100;
}

@media screen and (max-width: 860px){
    .signup__shot {
        left:-60px;
        top:-52px;
        width: 140px;
        height: auto;
        -webkit-transform: rotate(156deg);
            -ms-transform: rotate(156deg);
                transform: rotate(156deg);
    }
}