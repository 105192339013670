.main {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 200px;
    /* z-index: -1000; */
}

@media screen and (max-width: 1575px) {
    .main{
        padding: 0 100px;
    }
}

@media screen and (max-width: 1024px) {
    .main{
        padding: 0 40px;
    }
}

@media screen and (max-width: 860px) {
    .main{
        padding: 0 12px;
    }
}
