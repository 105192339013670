.footer {
    width: 100%;
    height: 428px;
    background-color: #161619;
}

.footer__container {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    padding: 60px 200px; 
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
}

.footer__logo {
    position: absolute;
    width: 108px;
    height: 73px;
    cursor: pointer;
    left: 200px;
    top: 60px;
}

.footer__copyright {
    font-family: Inter;
    font-size: 14px;
    line-height: 130%;
    color: #DCDCDC;
    position: absolute;
    bottom: 60px;
    left: 200px;
}

.footer__contacts {
    position: absolute;
    bottom: 128px;
    right: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
}

.footer__phone,
.footer__email{
    color: #fff;
    font-family: Inter;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-transform: none;
    text-align: right;
}

.footer__subscription_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: 385px;
    max-width: 365px;
}

.footer__subscription_title {
    color: #fff;
    margin-bottom: 30px;
}

.footer__subscription_input {
    height: 50px;
    border-radius: 10px;
    border: #fff 1px solid;
    background-color: #161619;
    margin-bottom: 12px;
    outline: none;
    color: #8E909B;
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    padding: 13.5px 20px;
}

.btn.footer__subscription_btn {
    width: 100%;
    height: 50px;
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: none;
}

.footer__subscription_caption {
    margin-top: 8px;
    color: #fff;
    font-family: Inter;
    font-weight: 400;
    line-height: 130%;
    font-size: 12px;
}

.footer__subscription_caption a {
    text-decoration: none;
    color: inherit;
    border-bottom: #fff 1px solid;
}

.footer__social {
    width: 100%;
    max-width: 365px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
}

.footer__social_title {
    color: #fff;
    margin-bottom: 30px;
}

.footer__social_links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 12px;
}

.footer__social_link-item {
    color: #fff;
    border: #fff 1px solid;
    height: 37px;
    padding: 7px 10px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 20px;
    font-family: Inter;
    font-size: 18px;
    line-height: 130%;
    font-weight: 400;
    cursor: pointer;
}

@media screen and (max-width: 1575px) {
    .footer__container {
        padding: 60px 100px; 
    }

    .footer__logo,
    .footer__copyright {
        left: 100px;
    }
    
    .footer__contacts {
        right: 100px;
    }

    .footer__subscription_container {
        margin-left: 285px;
    }
}

@media screen and (max-width: 1280px) {
    .footer {
        margin-top: 100px;
        height: 378px;
    }

    .footer__subscription_container {
        margin-left: 215px;
        max-width: 260px;
    }
    
    .footer__subscription_title {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .footer__subscription_input,
    .btn.footer__subscription_btn {
        height: 40px;
        font-size: 14px;
    }

    .footer__social {
        max-width: 280px;
    }

    .footer__social_title {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .footer__phone,
    .footer__email{
        font-size: 18px;
    }

    .footer__social_link-item {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .footer {
        height: 328px;
    }

    .footer__container {
        padding: 40px; 
    }

    .footer__logo {
        left: 40px;
        top: 40px;
    }
    
    .footer__copyright {
        left: 40px;
    }

    .footer__contacts {
        right: 40px;
    }

    .footer__contacts {
        bottom: 90px;
    }
}

@media screen and (max-width: 860px) {
    .footer {
        height: 619px;
    }

    .footer__container {
        padding: 12px; 
    }

    .footer__contacts {
        right: 12px;
        top: 20px;
        bottom: unset;
    }

    .footer__copyright {
        left: 12px;
        bottom: 40px;
        font-size: 14px;
    }

    .footer__logo {
        left: 12px;
        top: 20px;
        height: 49px;
        width: 73px;
    }

    .footer__container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 47px;
    }
    
    .footer__subscription_container {
        margin-left: 0;
        width: 100%;
        max-width: 400px;
    }

    .footer__social {
        position: relative;

        width: 100%;
        max-width: 400px;
    }

    .footer__social {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: start;
    }

    .footer__subscription_input,
    .btn.footer__subscription_btn {
        height: 50px;
    }
}

@media screen and (max-width: 460px) {
    .footer__social_title {
        max-width: 268px;
    }
}
