.card {
    width: 100%;
    max-width: 1920px;
    padding: 58px 200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.card__previous {
    top: 74px !important;
}

@media screen and (max-width: 1575px) {
    .card {
        padding: 58px 100px;
    }
}

@media screen and (max-width: 1024px) {
    .card {
        padding: 58px 40px;
    }
}

@media screen and (max-width: 860px) {
    .card {
        padding: 58px 12px 46px 12px;
    }
}
