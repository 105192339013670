.intro {
    position: relative;
    height: 782px;
}

.intro__gun {
    position: absolute;
    right: 0;
    top: 123px;
}

.intro__info_container {
    position: relative;
}

.intro__title {
    position: absolute;
    left: 130px;
    top: 155px;
    letter-spacing: 1.8px;
}

.intro__title_span {
    padding-left: 128px;
}

.intro__title::first-letter {
    color: #FFF;
}

.intro__shot {
    position: absolute;
    width: 584px;
    height: 519px;
    left: -154px;
    top: 75px;
}

.intro__subtitle {
    position: absolute;
    width: 494px;
    top:505px;
    left: 257px;
    line-height: 130%;
    color: #54555A;
}

.intro__button_container {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 58px;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    gap: 2px;
    border: none;
    background: transparent;
    top: 702px;
    left: 258px;
    cursor: pointer;
}

.intro__button_text {
    font-family: Inter;
    font-size: 20px;
    line-height: 130%;
    width: 294px;
    color: #FFF;
    background-color: #161619;
    border-radius: 10px 0 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media screen and (max-width: 1720px) {
    .intro {
        height: 635px;
    }

    .intro__gun {
        top: 73px;
        width: 575px;
        height: auto;
    }

    .intro__title {
        left: 95px;
        top: 145px;
        font-size: 74px;
    }

    .intro__shot {
        width: 484px;
        height: auto;
        left: -154px;
        top: 55px;
    }

    .intro__subtitle {
        width: 494px;
        top:405px;
        left: 227px;
    }

    .intro__button_container {
        height: 58px;
        top: 552px;
        left: 228px;
    }
}

@media screen and (max-width: 1575px) {
    .intro__button_container {
        height: 45px;
        top: 552px;
        left: 228px;
    }

    .intro__button_text {
        font-size: 18px;
        width: 250px;
    }
}


@media screen and (max-width: 1280px) {
    .intro {
        height: 478px;
    }
    
    .intro__gun {
        top: 43px;
        width: 445px;
        height: auto;
    }

    .intro__shot {
        width: 339px;
        height: auto;
        left: -120px;
        top: 55px;
    }

    .intro__title {
        left: 45px;
        top: 100px;
        font-size: 64px;
    }

    .intro__subtitle {
        width: 394px;
        top:305px;
        left: 177px;
        font-size: 14px;
    }

    .intro__button_container {
        height: 40px;
        top: 422px;
        left: 178px;
    }

    .intro__button_text {
        font-size: 14px;
        width: 200px;
    }
}

@media screen and (max-width: 1024px) {
    .intro__title_span {
        padding-left: 68px;
    }

    .intro__subtitle {
        left: 117px;
    }
    
    .intro__button_container {
        top: 402px;
        left: 117px;
    }
}

@media screen and (max-width: 860px) {
    .intro{
        height: 756px;
    }

    .intro__shot {
        width: 209px;
        left: 50%;
        -webkit-transform: translateX(-114.5%);
            -ms-transform: translateX(-114.5%);
                transform: translateX(-114.5%);
        top: 2px;
    }

    .intro__gun {
        top: 140px;
        left:50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 100%;
        max-width: 450px;
        height: auto;
    }

    .intro__title {
        left: 50%;
        -webkit-transform: translateX(-44%);
            -ms-transform: translateX(-44%);
                transform: translateX(-44%);
        top: 57px;
        line-height: 120%;
        font-size: 48px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .intro__title_span {
        padding-left: 38px;
    }

    .intro__subtitle {
        top:604px;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 100%;
        max-width: 400px;
    }

    .intro__button_container {
        top: 704px;
        width: 100%;
        max-width: 400px;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 52px;
    }

    .intro__button_text {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}

@media screen and (max-width: 474px) {
    .intro{
        height: calc(126vw * .8 + 280px);;
    }
    .intro__subtitle {
        top:calc(126vw * .8 + 100px);
        left: 0;
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
        width: 100%;
        max-width: 260px;
    }
    .intro__button_container {
        top: calc(126vw * .8 + 230px);
    }
}

@media screen and (max-width: 374px) {
    .intro__shot {
        left: 50%;
        -webkit-transform: translateX(-106%);
            -ms-transform: translateX(-106%);
                transform: translateX(-106%);
        top: 2px;
    }

    .intro__title {
        font-size: 38px;
    }
}

