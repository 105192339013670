.popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    -webkit-transition: visibility 0.2s, opacity 0.2s linear;
    -o-transition: visibility 0.2s, opacity 0.2s linear;
    transition: visibility 0.2s, opacity 0.2s linear;
    z-index: 2000;
}

.popup__opened {
    visibility: visible;
    opacity: 1;
}

.popup__close {
    width: 40px;
    height: 40px;
    background: url('../../images/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    position: absolute;
    right: 60px;
    top: 60px;
    cursor: pointer;
}

.popup__container {
    position: relative;
    width: 100%;
    max-width: 1006px;
    margin: 0 auto;
    background-color: #F3F3F3;
    border-radius: 24px;
    height: 100%;
    max-height: 529px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 32px;
}

.popup__content {
    width: 100%;
    max-width: 589px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}

.popup__conetnt_text:first-child {
    margin-bottom: 15px;
}

.popup__conetnt_text:nth-child(2) {
    margin-bottom: 30px;
}

.popup__conetnt_text:nth-child(2) span {
    font-weight: 600;
}

.popup__conetnt_text:nth-child(3),
.popup__conetnt_text:nth-child(4) {
    margin-bottom: 10px;
    font-weight: 600;
}

.popup__conetnt_text:nth-child(3) span,
.popup__conetnt_text:nth-child(4) span
{
    font-weight: 400;
}

@media screen and (max-width: 860px) {
    .popup__container {
        max-height: 371px;
    }

    .popup__close {
        width: 24px;
        height: 24px;
        right: 30px;
        top: 30px;
    }
}
