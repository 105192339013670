.about {
    width: 100%;
    min-height: 400px;
    border-radius: 24px;
    background-color: #F3F3F3;
    margin-top: 158px;
    padding: 0 128px 80px 128px;
    z-index: 0;
}

.title__container {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 157px;
}

.about__title {
    padding: 0;
    margin: 0;
    position: absolute;
    line-height: 100%;
    transform: translateY(93px);
}

.about__shot {
    position: absolute;
    top: 0;
    transform: translateY(-38px);
    left: 110px;
}

.about__title span {
    color: #fff;
}

.about__title span:first-child {
    color: #FF0000;
}

.about__container {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 40px;
}

.about__content_title {
    color: #161619;
    margin-bottom: 30px;
}

.about__content {
    width: 100%;
    max-width: 494px;
}

.about__content_text {
    color: #54555A;
}

@media screen and (max-width: 1280px) {
    .about {
        padding: 0 60px 60px 60px;
        margin-top: 100px;
    }

    .about__shot {
        left: 110px;
    }
}

@media screen and (max-width: 860px) {
    .about__shot {
        width: 140px;
        height: auto;
        top: 0;
        left: 71px;
        transform: translateY(-3px);
    }
    
    .title__container {
        height: 78px;
        align-items: end;
    }

    .about__title {
        transform: translateY(46px);
    }

    .about {
        padding: 0 7px 40px 7px;
        border-radius: 18px;
    }

    .about__container {
        margin-top: 40px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 40px;
    }

    .about__content {
        width: 100%;
        max-width: unset;
    }

    .about__content_title {
        margin-bottom: 20px;
    }
}