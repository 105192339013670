.locations {
    width: 100%;
    margin-bottom: 160px;
}

.locations__container {
    position: relative;
    height: 100%;
    width: 100%;
}

.locations__shot {
    position: absolute;
    top:-114px;
    left: 520px;
    z-index: -100;
}

.locations__title {
    margin-bottom: 80px;
}

.locations__title span {
    color: #FF0000;
}

.locations__title span:last-child {
    color: #ffffff;
}

.locations__dropdown-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap:20px;
    margin-bottom: 60px;
}

.locations__dropdown_list {
    width: 100%;
    max-width: 365px;
    height: 50px;
    background-color: #F9F9F9;
    border: none;
    border-radius: 10px;
    color: #B3B3B3;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    padding: 0 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none; 
}

.locations__custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 365px;
}

.locations__dropdown_item {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
}

.locations_map_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 700px;
    width: 100%;
    gap: 20px;
}

.locations__slider_container {
    width:100%;
    max-width: 365px;
    background-color: #F9F9F9;
    border-radius: 13px 0 0 16px;
    position: relative;
    border: #F3F3F3 1px solid;
}

.location__slider_finder-container {
    position: absolute;
    left:0;
    right:8px;
    top:0;
    background-color: #F9F9F9;
    height: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 20px;
}

.locations__slider_finder {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 0 20px;
    color: #959595;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
}

.locations__slider {
    overflow-y: auto; 
    height: 100%;
    padding: 90px 40px 20px 40px;
}

.locations__slider::-webkit-scrollbar {
    width: 8px;
}

.locations__slider::-webkit-scrollbar-track {
    background: #fff;
}

.locations__slider::-webkit-scrollbar-thumb {
    background: #9B9B9B; 
    border-radius: 6px;
}

.locations__slider::-webkit-scrollbar-thumb:hover {
    background: #555; 
    cursor: pointer;
}

.locations__slider::-webkit-scrollbar-thumb:active {
    background: #333;
}

.locations__slider_letter {
    margin-top: 40px;
    padding-bottom: 12px;
}

.locations__slider_name {
    margin-top: 8px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;
}

.locations__slider_name-active {
    font-weight: 600;
}

.locations__map {
    width:100%;
    max-width: 1135px;
    border-radius: 0 16px 16px 0;
    overflow: hidden;
}

.locations__toggle-mobile {
    display: none;
}

@media screen and (max-width:860px) {
    .locations {
        margin-bottom: 0;
    }

    .locations__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        overflow: hidden;
        padding-top: 60px;
    }

    .locations__shot {
        top: 26px;
        left: 275px;
        width: 116px;
        height: 112px;
    }

    .locations__title {
        margin-bottom: 40px;
        -ms-flex-item-align: start;
            -ms-grid-row-align: start;
            align-self: start;
    }

    .locations_map_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        gap: 0;
        overflow: hidden;
    }

    .locations__slider_container {
        width:100%;
        max-width: unset;
        background-color: #F9F9F9;
        border-radius: 14px;
        position: relative;
        border: #F3F3F3 1px solid;
        max-height: 80vh;
    }

    .locations__map {
        position: absolute;
        width:100%;
        max-width: unset;
        border-radius: 14px;
        overflow: hidden;
        max-height: 80vh;
        top: 362px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .locations__dropdown-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 12px;
        margin-bottom: 40px;
        width: 100%;
    }

    .locations__toggle-mobile {
        position: relative;
        display: inline-block;
        width: 160px;
        height: 38px;
        margin-bottom: 40px;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .locations__toggle-slider {
        border-radius: 6px;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #F3F3F3;
        -webkit-transition: 400ms;
        -o-transition: 400ms;
        transition: 400ms;
    }

    .locations__toggle_span-left,
    .locations__toggle_span-right {
        position:absolute;
        z-index: 100;
        color: #fff;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        -webkit-transition: color .4s;
        -o-transition: color .4s;
        transition: color .4s;
    }

    .locations__toggle_span-left {
        left:14.5px;
    }

    .locations__toggle_span-right {
        right:20px;
    }
    
    .locations__toggle-slider:before {
        position: absolute;
        border-radius: 6px;
        content: "";
        height: 38px;
        width: 80px;
        left: 0px;
        bottom: 0px;
        background-color: #161619;
        -webkit-transition: 400ms;
        -o-transition: 400ms;
        transition: 400ms;
    }
    
    .locations__toggle-input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    .locations__toggle-input:checked + .locations__toggle-slider:before {
        -webkit-transform: translateX(80px);
        -ms-transform: translateX(80px);
        transform: translateX(80px);
    }

    .locations__slider_name {
        margin-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
        border-bottom: #E8E8E8 1px solid;
    }

    .locations__slider_name:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

@media screen and (max-width:368px) {
    .locations__shot {
        top: 60px;
        left: 145px;
    }

    .locations__map {
        top: 398px;
    }
}





