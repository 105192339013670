.playercard {
    padding-top: 63px;
    position: relative;
}

.playercard__title_container {
    position: relative;
}

.playercard__title {
    text-wrap: nowrap;
    margin-bottom: 129px;
}

.playercard__title span{
    color: #FF0000;
}

.playercard__shot {
    position: absolute;
    top: -16px;
    left: 390px;
    z-index: -1;
}

.playercard__title span:last-child{
    color: #ffffff;
}

.playercard__card_container {
    width: 100%;
    position: relative;
    padding-top: 27.106%;
}

.playercard__card_container h3 {
    color: #fff;
}

.playercard__card {
    position: absolute;
    width: 49.345%;
    height: 100%;
    border-radius: 20px;
    background-color: #FF0000;
    border: none;
    top:0;
    padding: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}

.playercard__card_content {
    left:0;
}

.playercard__content-upper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
}

.playercard__level_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    gap: 5px;
}

.playercard__count-win {
    max-width: 200px;
    font-family: Inter;
    font-size: 24px;
    line-height: 120%;
    font-weight: 600;
    color: #fff;
}

.playercard__shots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 12px;
}

.playercard__target-shot {
    width: 15.672%;
    height: auto;
}

.playercard__content_lower {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
}

.playercard__card_back {
    right:0;
}

.playercard__card_back h3{
    color: #161619;
}

.playercard__content_lower-back {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    position: relative;
}

.playercard__qr {
    position: absolute;
    top:-50px;
    height: 93.25%;
    width: auto;
}

.playercard__back_caption {
    position: absolute;
    bottom: 0;
    max-width: 433px;
    text-align: center;
    color: #fff;
}


@media screen  and (max-width: 1575px){

}

@media screen  and (max-width: 1280px){
    .playercard__card {
        padding: 20px;
    }

    .playercard__card_container  {
        font-size: 14px;
    }

    .playercard__logo {
        width: 80px;
        height: auto;
    }

    .playercard__bullet {
        width: 58px;
        height: auto;
    }

    .playercard__count-win {
        font-size: 18px;
    }

    .playercard__nikname {
        font-size: 18px;
    }

    .playercard__content-upper img{
        width: 70px;
        height: auto;
    }

    .playercard__back_caption {
        font-size: 14px;
        max-width: 350px;
    }
}

@media screen  and (max-width: 1024px){
    .playercard__title {
        margin-bottom: 40px;
    }
}

@media screen  and (max-width: 860px){
    .playercard__shot {
        width: 117px;
        height: auto;
        -webkit-transform: rotate(80deg);
            -ms-transform: rotate(80deg);
                transform: rotate(80deg);
        left: 198px;
        top:-38px;
    }

    .playercard {
        padding-top: 0;
    }

    .playercard__card_container {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 20px;
        padding-top: 0;
        height: 100%;;
    }

    .playercard__card_padding {
        position: relative;
        width: 100%;
        height: 100%;
        padding-bottom: 61.5%;
        border-radius: 16px;
        background-color: #FF0000;
        border: none;
        overflow: visible;
    }

    .playercard__card {
        position: absolute;
        border-radius: 16px;
        width: 100%;
        height: 100%;
        min-width: 250px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }

    .playercard__shots {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        gap: 1.86px;
    }

    .playercard__bullet {
        width: 40px;
    }

    .playercard__target-shot {
        width: 19.45%;
        border-radius: 8px;
    }

    .playercard__count-win {
        font-size: 14px;
        font-weight: 500;
        width: 132px;
    }
    
    .playercard__qr {
        top: unset;
        bottom: 0;;
    }
    
    .playercard__back_caption {
        color: #161619;
        font-size: 18px;
        font-weight: 500;
        line-height: 130%;
        max-width: 436px;
        bottom: -66px;
    }
}    

@media screen  and (max-width: 470px){
    .playercard__logo {
        width: 43.24px;
    }

    .playercard__content-upper img{
        width: 40px;
        height: auto;
    }

    .playercard__nikname {
        font-size: 14px;
        font-weight: 500;
    }

    .playercard__back_caption {
        font-size: 14px;
        max-width: 436px;
        bottom: -66px;
        width: 110%;
    }
}

@media screen  and (max-width: 350px){
    .playercard__back_caption {
        font-size: 12px;
        max-width: 436px;
        bottom: -66px;
        width: 110%;
    }
}