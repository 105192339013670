.profile {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    padding: 100px 200px;
    overflow: hidden;
}

.profile__about_container {
    width: 100%;
    margin-top: 85px;
    height: fit-content;
    display: grid;
    grid-template-columns: 493px 1fr;
    column-gap: 150px;
}

.profile__about_avatar {
    width: 493px;
    height: 493px;
    margin-top: 6px;
}

.profile__about_info {
    width: 100%;
    max-width: 878px;
    position: relative;
}

.profile__info_container {
    display: flex;
    flex-direction: column;
}

.profile__title_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 878px;
    right: 0;
}

.profile__title {
    transform: translateY(-30px);
    position: relative;
}

.profile__title span,
.profile__title_mobile span{
    color: #fff;
}

.profile__title span:last-child,
.profile__title_mobile span:last-child{
    color: #FF0000;
}

.profile__shot {
    position: absolute;
    top: -90px;
    left: -100px;
    z-index: -1;
}

.profile__title_rank {
    position: relative;
    transform: translateY(-30px);
}

.profile__title_rank span {
    font-family: Inter;
    font-size: 12px;
    line-height: 130%;
    font-weight: 400;
    color: #54555A;
    position: absolute;
    width: 90px;
    text-align: center;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
}

.profile__info_list {
    left: 0;
    bottom: 0;
    list-style-type: none;
    width: 100%;
    max-width: 570px;
}

.profile__info_row {
    margin-top: 28px;
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    align-items: center;
}

.profile__info_row div {
    width: 57.65px;
}

.profile__info_param {
    font-family: Inter;
    font-size: 24px;
    line-height: 130%;
    font-weight: 600;
    color: #161619;
}

.profile__info_value {
    color: #54555A;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.profile__info_value-nik {
    color: #161619;
}

.profile__pass_eye {
    margin-left: 14px;
    transform: translateY(-2px);
}

.profile__info_edit {
    color: #54555A;
    font-family: Inter;
    font-size: 12px;
    line-height: 130%;
    font-weight: 400;
    border-bottom: #54555A 1px solid;
    cursor: pointer;
}

.profile__title_mobile {
    display: none;
}

@media screen and (max-width: 1680px) {
    .profile__about_container {
        column-gap: 40px;
    }
}

@media screen and (max-width: 1575px) {
    .profile{
        padding: 100px 100px;
    }
}

@media screen and (max-width: 1370px) {
    .profile__title {
        display: none;
    }

    .profile__title_mobile {
        display: block;
        margin-top: 100px;
    }

    .profile__shot {
        top: 110px;
        left: 0px;
        z-index: -1;
    }

    .profile__title_container {
        justify-content: end;
    }

    .profile__about_avatar {
        width: 400px;
        height: 400px;
        margin-top: 6px;
    }

    .profile__title_rank {
        position: absolute;
        right: 100px;
        top:230px;
    }

    .profile__about_container {
        grid-template-columns: 400px 1fr;
        column-gap: 40px;
    }


}

@media screen and (max-width: 1024px) {
    .profile{
        padding: 40px;
    }

    .profile__shot {
        top: -30px;
        left: -60px;
        z-index: -1;
    }

    .profile__title_rank {
        right: 40px;
        top: 90px;
    }

    .profile__title_mobile {
        margin-top: 20px;
    }

}

@media screen and (max-width: 860px) {
    .profile{
        padding: 40px 12px;
    }

    .profile__about_container {
        grid-template-columns: 1fr;
        grid-template-rows: 170px 1fr;
        column-gap: 42px;
        margin-top: 50px;

    }

    .profile__about_avatar {
        width: 170px;
        height: 170px;
        margin-top: 0;
    }

    .profile__shot {
        width: 106px;
        height: 106px;
        top: 6px;
        left: -28px;

    }

    .profile__info_list {
        max-width: none;
    }

    .profile__title_mobile {
        margin-top: 0px;
    }

    .profile__title_rank {
        right: 12px;
        top: 200px;
    }

    .profile__title_rank span {
        font-size: 10px;
        bottom: -24px;
    }

    .profile__info_param {
        font-size: 18px;
    }

    .profile__info_value {
        font-size: 14px;
    }

    .profile__info_row {
        margin-top: 20px;
    }
}

