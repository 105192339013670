.previous {
    position: absolute;
    left: 200px;
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    cursor: pointer;
}

@media screen and (max-width: 1575px){
    .previous {
        left: 100px;
    }
}

@media screen and (max-width: 1024px){
    .previous {
        display: none;
    }
}