@import url(./vendor/fonts.css);
@import url(./vendor/normalize.css);

@import url(../src/components/App/App.css);
@import url(../src/components/Header/Header.css);
@import url(../src/components/Footer/Footer.css);

@import url(../src/components/Main/Main.css);
@import url(../src/components/Main/Intro/Intro.css);
@import url(../src/components/Main/About/About.css);
@import url(../src/components/Main/Video/Video.css);
@import url(../src/components/Main/Rating/Rating.css);
@import url(../src/components/Main/Locations/Locations.css);
@import url(../src/components/Main/Locations/Dropdown/Dropdown.css);

@import url(../src/components/AuthForm/AuthForm.css);
@import url(../src/components/SignIn/SignIn.css);
@import url(../src/components/SignUp/SignUp.css);
@import url(../src/components/Popup/Popup.css);
@import url(../src/components/Profile/Profile.css);
@import url(../src/components/Previous/Previous.css);
@import url(../src/components/Card-page/Card.css);
@import url(../src/components/PlayerCard/PlayerCard.css);
@import url(../src/components/HistoryGames/HistoryGames.css);

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

*::before, *::after {
    box-sizing: inherit;
}

button, 
a,
div {
    -webkit-tap-highlight-color: transparent;
}

a, 
h1,
h2,
h3,
p,
button,
label {
    color: #161619;
}

a,
label,
button,
input {
    text-decoration: none;
    font-family: Inter;
}

h1 {
    font-family: Terminator;
    font-size: 110px;
    font-weight: 400;
    line-height: 110%;
}

h2 {
    font-family: Terminator;
    font-size: 64px;
    font-weight: 400;
    line-height: 130%;
}

h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
}

p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
}

.text-menu {
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    line-height: 130%;
    cursor: pointer;
}

.text-menu::first-letter {
    text-transform: uppercase;
}

.btn {
    height: 45px;
    border-radius: 8px;
    cursor: pointer;
    width: 165px;
    border: none;
}

@media screen and (max-width: 1280px) {
    .text-menu {
        font-size: 14px;
    }
    
    .btn {
        width: 135px;
        height: 40px;
    }
}

@media screen and (max-width: 860px) {
    h2 {
        font-size: 32px;
        line-height: 130%;
    }

    h3 {
        font-size: 18px;
        line-height: 130%;
    }

    p {
        font-size: 14px;
        line-height: 16px;
    }
}