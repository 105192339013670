.video {
    margin-top: 160px;
    width: 100%;
    height: 100%;
}

.video__container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
}

.video__container iframe {
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

@media screen and (max-width: 1575px) {
    .video {
        margin-top: 100px;
    }
}

@media screen and (max-width: 1024px) {
    .video__container iframe {
        border-radius: 14px;
    }
}