.history {
    width: 100%;
    max-width: 1920px;
    margin: 140px auto 0 auto;
    padding: 0;
    overflow: visible;
    display: flex;
    flex-direction: column;
}

.history__title span{
    color: #fff;
}

.history__title span:last-child{
    color: #FF0000;
}

.history__title_container {
    overflow: visible;
    position: relative;
    margin-bottom: 70px;
}

.history__shot {
    position: absolute;
    left:-105px;
    top:-90px;
    z-index: -1;
}

.history__table_header {
    list-style-type: none;
    display: grid;
    grid-template-columns:  7% 12% 21.5% 17% 31.2% 6%;
    padding-left: 3%;
    padding-right: 3%;
    column-gap: 10px;
    border-bottom: #EAEAEA 1px solid;
    padding-bottom: 20px; 
    margin-bottom: 15px;
    justify-content: space-between;
}

.history__table_header-item {
    width: 100%;
    text-align: start;
    font-family: Inter;
    color: #54555A;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

.history__table_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
}

.history__item {
    width: 100%;
    min-height: 126px;
    background-color: #F7F7F7;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 7% 12% 21.5% 17% 31.2% 6%;
    padding: 6px 3%;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.history__item:nth-child(even) {
    background-color: #E8F3F2;
}

.history__item_place {
    font-size: 18px;
    font-weight: 600;
}

.history__item_orders-title {
    display: none;
}


.history__item_orders {
    padding-left: 24px;
}

.history__item_order-item {
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    line-height: 130%;
}

.history__item_coins {
    font-family: Inter;
    font-weight: 700;
    line-height: 120%;
    font-size: 28px;
}

.history__pagin {
    align-self: center;
    display: flex;
    gap: 8px;
    margin-top: 40px;
    margin-bottom: 77px;
}

.history__btn_pagin {
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 50%;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(27, 27, 27, 0.05);
}

.history__pagin_left {
    background-image: url('../../images/profile/arrow_left.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #E9E9E9;
    cursor: default;
}

.history__pagin_right {
    background-image: url('../../images/profile/arrow_right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #E9E9E9;
    cursor: default;
}

.history__pagin_arrow-active {
    background-color: #161619;
    cursor: pointer;
}

.history__pagin_page {
    background: transparent;
    box-shadow: none;
}

.history__pagin_page:hover {
    background: #E9E9E9;
    transition: .3s;
}

.history__pagin_page-active {
    background-color: #629692;
    color: #fff;
    cursor: default;
}

.history__pagin_ellipsis {
    border: none;
    background: transparent;
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width: 1280px) {
    .history__item_coins {
        font-size: 20px;
    }

    .history__item {
        padding: 6px 2%;
        column-gap: 4px;
    }
}

@media screen and (max-width: 860px) {
    .history__item {
        min-height: 198px;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: start;
        padding: 20px;
        justify-content: space-between;
    }

    .history__item_place {
        font-size: 16px;
    }

    .history__item_address {
        font-size: 12px;
    }

    .history__item_coins {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .history__item_orders-title {
        display: inline-block;
        font-family: Inter;
        font-size: 14px;
        line-height: 130%;
        font-weight: 500;
    }

    .history__item_order-item {
        font-size: 12px;
        line-height: 130%;
    }

    .history__table_header {
        display: none;
    }

    .history__shot {
        left:-42px;
        top:-36px;
        width: 110px;
        height: 110px;
    }

    .history__item_num {
        display: none;
    }

    .history__btn_pagin {
        width: 36px;
        height: 36px;
    }
}