@font-face {
    src:url(./fonts/Terminator-Gen-Regular.woff2) format("woff2"),
        url(./fonts/Terminator-Gen-Regular.otf) format("opentype"),
        url(./fonts/Terminator-Gen-Regular.woff) format("woff");
        
    font-family: "Terminator";
    font-weight: 400;
}

@font-face {
    src: url(./fonts/SF-Pro-Text-Regular.woff) format("woff"),
         url(./fonts/SF-Pro-Text-Regular.woff2) format("woff2");
    font-family: "SF";
    font-weight: 400;
}

@font-face {
    src: url(./fonts/SF-Pro-Text-Medium.woff) format("woff"),
         url(./fonts/SF-Pro-Text-Medium.woff2) format("woff2");
    font-family: "SF";
    font-weight: 500;
}
    
@font-face {
    src: url(./fonts/SF-Pro-Text-Semibold.woff) format("woff"),
         url(./fonts/SF-Pro-Text-Semibold.woff2) format("woff2");
    font-family: "SF";
    font-weight: 600;
}

@font-face {
    src: url(./fonts/Inter-Regular.woff) format("woff"),
         url(./fonts/Inter-Regular.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 400;
}
    
@font-face {
    src: url(./fonts/Inter-Medium.woff) format("woff"),
         url(./fonts/Inter-Medium.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 500;
}

@font-face {
    src: url(./fonts/Inter-SemiBold.woff) format("woff"),
         url(./fonts/Inter-SemiBold.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 600;
}

@font-face {
    src: url(./fonts/Inter-Bold.woff2) format("woff2");
    font-family: "Inter";
    font-weight: 700;
}
