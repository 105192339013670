.signin__title_container {
    position: relative;
}

.signin__shot {
    position: absolute;
    left:-112px;
    top:-94px;
    z-index: 100;
}

@media screen and (max-width: 860px){
    .signin__shot {
        left: -40px;
        top: -34px;
        width: 106px;
        height: auto;
        -webkit-transform: rotate(16deg);
            -ms-transform: rotate(16deg);
                transform: rotate(16deg);
    }
}