.header__ident {
    height: 103px;
    background-color: #FFF;
}

.header {
    width: 100%;
    height: 103px;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #FFF;
    z-index: 500;
}

.header__container {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 200px;
}

.header__logo {
    width: 108px;
    height: 73px;
    cursor: pointer;
}

.header__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 60px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.header__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.header__lang {
    background-color: #F3F3F3;
    border: 0;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 88px;
    height: 45px;
    gap: 10px;
    cursor: pointer;
}

.header__lang_text {
    padding-top: 0px;
    -webkit-text-stroke: 1.5px black;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    line-height: 130%;
}

.header__buttons_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
}

.btn.header__signup {
    background-color: #FFF;
    border: #54555A 1px solid;
}

.btn.header__signin {
    background-color:#161619;
    color: #FFF;
    width: 170px;
    border: none;
}

.header__burger_container,
.header__menu_item-mobile,
.header__signup-mobile,
.header__signin-mobile,
.header__signout-mobile {
    display: none;
}

.btn.header__signout {
    width: 107px;
    border: #54555A 1px solid;
}

.btn.header__profile {
    width: 50px;
    height: 45px;
    background: url('../../images/profile.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #161619;
    background-size:23px 30px;
    border: none;
}

.header__profile-text {
    display: none;
}

.text-menu.header__player-card_active {
    font-weight: 600;
}

@media screen and (max-width: 1575px) {
    .header__ident {
        height: 103px;
    }
    
    .header {
        margin-top: 0;
    }
    
    .header__container {
        padding: 0 100px;
    }
    
    .header__logo {
        margin-right: 0;
    }
    
    .header__menu {
        gap: 20px;
    }
}

@media screen and (max-width: 1280px) {
    .header__logo {
        width: 73px;
        height: 49px;
    }
    
    .header__menu {
        gap: 10px;
    }
    
    .header__lang {
        width: 80px;
        height: 40px;
    }

    .header__buttons_container {
        gap: 10px;
    }

    .header__btns {
        gap: 10px;
    }
    
    .btn.header__signin {
        width: 138px;
    }

    .btn.header__profile {
        width: 40px;
        height: 37.5px;
        background-size:18px 22px;
    }
}

@media screen and (max-width: 1024px) {
    .header__ident {
        height: 49px;
    }
    
    .header {
        height: 49px;
    }
    
    .header__container {
        position: relative;
        height: 100%;
        padding: 0;
    }
    
    .header__logo {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
    }

    .header__dark-background {
        position: fixed;
        top: 0;
        left: 0;
        height: 200vh;
        width: 100vw;
        background-color: rgba(0, 0, 0);
        z-index: 800;
        opacity: 0;
        visibility: hidden; 
        -webkit-transition: opacity .4s ease; 
        -o-transition: opacity .4s ease; 
        transition: opacity .4s ease;
    }
    
    .header__dark-background.header__dark-background-active {
        opacity: 0.5;
        visibility: visible;
        -webkit-transition: opacity .4s ease;
        -o-transition: opacity .4s ease;
        transition: opacity .4s ease;
    }

    .header__menu_container {
        position: fixed;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        right: 0;
        top: 0;
        height: 100vh;
        background-color: #F3F3F3;
        width: 72.53vw;
        padding: 80px 40px 120px 40px;
        -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
                transform: translateX(100%);
        z-index: 900;
    }

    .header__menu-mobile_active {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0) ;
            transform: translateX(0) ;
        -webkit-transition:  .3s; 
        -o-transition:  .3s; 
        transition:  .3s;
    }
    
    .header__menu-mobile_inactive {
        -webkit-transition:  .3s;
        -o-transition:  .3s;
        transition:  .3s;
    }

    .header__menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: unset;
            -ms-flex-align: unset;
                align-items: unset;
    }

    .header__menu_item-mobile {
        display: block;
    }
    
    .header__lang {
        position: absolute;
        left: 40px;
    }

    .header__menu_btns-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 10px;
    }

    .btn.header__signup-mobile {
        display: block;        
        background-color: #FFF;
        border: #54555A 1px solid;
        width: 192px;
        height: 37px;
    }
    
    .btn.header__signin-mobile {
        display: block;
        color: #FFF;
        width: 192px;
        height: 37px;
        background-color: #161619;
        border: none;
    }

    .btn.header__signout-mobile {
        display: block;
        background-color: #F3F3F3;
        border: #54555A 1px solid;
        color: #161619;
    }

    .header__burger_container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        position: absolute;
        top: 7px;
        right: 40px;
        width: 54px;
        height: 36px; 
        background-color: #F3F3F3;
        border-radius: 8px;
        border: none;
        cursor: pointer;
    }

    .header__signin,
    .header__signup,
    .btn.header__profile,
    .btn.header__signout {
        display: none;
    }

    .burger {
        position: relative;
        width: 30px;
        height: 16px;
        cursor: pointer;
        z-index: 1000;
    }
    .burger div {
        position: absolute;
        width: 100%;
        height: 1.5px;
        background-image: url(../../images//burger-line.svg);
        transition: transform 0.3s, opacity 0.3s;
        border-radius: 2px;
        border: none;
    }
    .burger div:nth-child(1) {
        top: 0;
    }
    .burger div:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
    }
    .burger div:nth-child(3) {
        bottom: 0;
    }
    .burger.burger_active div:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
    }
    .burger.burger_active div:nth-child(2) {
        transform: translateY(0px) rotate(45deg);
        opacity: 0;
    }
    .burger.burger_active div:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .header__profile-text {
        display: inline;
    }
}

@media screen and (max-width: 860px) {
    .header__burger_container {
        right: 12px;
    }

    .header__lang {
        left: 12px;
        height: 36px;
        width: 72px;
        padding: 0 7.5px;
    }

    .header__lang_text{
        -webkit-text-stroke: unset;
        text-shadow: unset;
        font-size: 14px;
    }

    .header__lang_flag {
        height: 12px;
        width: 18px;
    }
}