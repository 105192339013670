
.authform {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    padding: 100px 200px 0 200px;
}

.authform__container {
    margin-bottom: 160px;
    width: 100%;
    max-width: 1006px;
    background-color: #F3F3F3;
    border-radius: 24px;
    padding: 92px 20px 140px 20px;
}

.authform__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    max-width: 494px;
    margin: 0 auto;
    
}

.authform__title {
    position: relative;
    margin-bottom: 67px;
    line-height: 120%;
    z-index: 200;
}

.authform__title span {
    color: #fff;
}

.authform__title span:last-child {
    color: #FF0000;
}

.authform__label {
    -ms-flex-item-align:start;
        align-self:flex-start;
    margin-bottom: 8px;
    margin-top: 23px;
    font-size: 18px;
    font-weight: 400;
}

.authform__input {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 10px;
    color: #161619;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    outline: none;
}

.btn.authform__submit {
    width: 100%;
    background-color: #161619;
    color: #fff;
    height: 65px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    border: none;
}

.btn.authform__submit_disabled {
    background-color: #F3F3F3;
    color: #959595;
    cursor: not-allowed;
    border: #959595 1px solid;
}

.authform__caption {
    margin-top: 30px;
}

.authform__caption br{
    display: none;
}

.authform__caption_link {
    font-weight: 600;
    border-bottom: #161619 1px solid;
}

.authform_error {
    color: #FF0000;
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin: 4px 0 0 0;
    padding: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 12.1px;
    height: 13px;
}

@media screen and (max-width: 1280px){
    .authform__container {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1024px){
    .authform {
        padding: 0 100px;
    }

    .authform__container {
        margin-top: 100px;
    }
}

@media screen and (max-width: 860px){
    .authform {
        padding: 0 12px;
    }

    .authform__title {
        margin-bottom: 40px;
    }

    .authform__container {
        padding: 62px 20px 53px 20px;
    }

    .authform__label {
        -ms-flex-item-align:start;
            align-self:flex-start;
        margin-bottom: 5px;
        margin-top: 10px;
        font-size: 14px;
    }

    .authform__input {
        height: 50px;
        padding: 0 16px;
    }

    .btn.authform__submit {
        height: 50px;
        margin-top: 20px;
        font-size: 14px;
        
    }

    .authform__caption {
        text-align: center;
        line-height: 140%;
        margin-top: 20px;
    }

    .authform__caption br{
        display: block;
    }
}